import React, { useState, useEffect, useRef, useCallback } from 'react';
import './FeedPage.css';
import { toast } from 'react-toastify';

function FeedPage() {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const fetchedRef = useRef(false);

  const fetchQuestions = useCallback(async (pageNum) => {
    if (fetchedRef.current && pageNum === 1) return;
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/questions?page=${pageNum}&limit=10`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.length === 0) {
        setHasMore(false);
      } else {
        setQuestions(prev => pageNum === 1 ? data : [...prev, ...data]);
        fetchedRef.current = true;
      }
    } catch (error) {
      setError('Failed to load questions. Please try again later.');
      toast.error('Failed to load questions. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchQuestions(1);
  }, [fetchQuestions]);

  const handleLoadMore = useCallback(() => {
    if (!loading && hasMore) {
      setPage(prevPage => prevPage + 1);
      fetchQuestions(page + 1);
    }
  }, [loading, hasMore, page, fetchQuestions]);

  if (loading && questions.length === 0) return <div>Loading...</div>;
  if (error && questions.length === 0) return <div>{error}</div>;

  return (
    <div className="feed-container">
      <h2>Question Feed</h2>
      {questions.length === 0 ? (
        <EmptyState />
      ) : (
        <div className="question-list">
          {questions.map(question => (
            <QuestionCard key={question._id} question={question} />
          ))}
        </div>
      )}
      {loading && <LoadingSpinner />}
      {error && <div className="error-message">{error}</div>}
      {!loading && !error && hasMore && (
        <button onClick={handleLoadMore} className="load-more-btn">
          Load More
        </button>
      )}
      {!hasMore && <p>No more questions to load.</p>}
    </div>
  );
}

const EmptyState = () => (
  <div className="empty-state">
    <h3>No questions yet</h3>
    <p>Be the first to ask a question!</p>
  </div>
);

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

const QuestionCard = ({ question }) => (
  <div className="question-card">
    <h3>{question.title}</h3>
    <p>{question.content}</p>
    {question.fileUrl && (
      <div className="question-file">
        <a href={`${process.env.REACT_APP_API_URL}${question.fileUrl}`} target="_blank" rel="noopener noreferrer" className="view-file-btn">
          <i className="fas fa-file"></i> View Attached File ({question.fileUrl.split('/').pop()})
        </a>
      </div>
    )}
    <div className="question-meta">
      <span>Category: {question.category}</span>
      <span>Date: {new Date(question.createdAt).toLocaleDateString()}</span>
    </div>
  </div>
);

export default React.memo(FeedPage);