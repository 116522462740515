import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import './UploadQuestion.css';

function UploadQuestion() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
      'application/pdf': ['.pdf']
    },
    maxFiles: 1,
    multiple: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!title || !content || !category) {
      toast.error("Please fill in all required fields.");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('category', category);
    if (file) {
      formData.append('file', file);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/questions`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Upload successful:', data);
        toast.success("Question uploaded successfully!");
        setTitle('');
        setContent('');
        setCategory('');
        setFile(null);
      } else {
        const errorData = await response.json();
        console.error('Upload failed:', errorData);
        toast.error(errorData.message || "Failed to upload question");
      }
    } catch (error) {
      console.error('Error uploading question:', error);
      toast.error("An error occurred while uploading the question");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="upload-question-container">
      <div className="upload-question-card">
        <h2>Upload Your Question</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Enter your question title"
            />
          </div>
          <div className="form-group">
            <label htmlFor="content">Content</label>
            <textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
              placeholder="Describe your question in detail"
            />
          </div>
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Select a category</option>
              <option value="technology">Technology</option>
              <option value="science">Science</option>
              <option value="mathematics">Mathematics</option>
              <option value="history">History</option>
              <option value="literature">Literature</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label>Attach File (optional)</label>
            <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
              <input {...getInputProps()} />
              {
                file
                  ? <p>File selected: {file.name}</p>
                  : <p>Drag 'n' drop an image or PDF here, or click to select a file</p>
              }
            </div>
          </div>
          <button type="submit" disabled={isLoading} className="submit-button">
            {isLoading ? 'Uploading...' : 'Publish Question'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default UploadQuestion;