import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import useAuth from '../hooks/useAuth';

function Header() {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo-link">
          <h1>SolveStream</h1>
        </Link>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/feed">Feed</Link></li>
            {isAuthenticated && (
              <>
                <li><Link to="/upload-question">Upload Question</Link></li>
                <li><Link to="/profile">Profile</Link></li>
                <li><button onClick={handleLogout} className="btn btn-logout">Logout</button></li>
              </>
            )}
            {!isAuthenticated && (
              <li><Link to="/login" className="btn btn-login">Login</Link></li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;