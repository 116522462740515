import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../hooks/useAuth';
import './Profile.css';

function Profile() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      setIsDeleting(true);
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/user`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          toast.success('Account deleted successfully');
          logout();
          navigate('/');
        } else {
          const data = await response.json();
          console.error('Error deleting account:', data);
          toast.error(data.message || 'Failed to delete account');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        toast.error('An error occurred while deleting your account');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <div className="profile-container">
      <h2>Your Profile</h2>
      <div className="profile-info">
        <p><strong>Email:</strong> {user?.email || 'N/A'}</p>
        <p><strong>User Type:</strong> {user?.userType ? (user.userType.charAt(0).toUpperCase() + user.userType.slice(1)) : 'N/A'}</p>
      </div>
      <div className="profile-actions">
        <button onClick={handleLogout} className="btn btn-logout">Logout</button>
        <button onClick={handleDeleteAccount} className="btn btn-delete" disabled={isDeleting}>
          {isDeleting ? 'Deleting...' : 'Delete Account'}
        </button>
      </div>
      <div className="future-features">
        <h3>Coming Soon</h3>
        <div className="feature-placeholder">
          <i className="fas fa-star"></i>
          <p>User Ratings</p>
        </div>
        <div className="feature-placeholder">
          <i className="fas fa-key"></i>
          <p>Change Password</p>
        </div>
      </div>
    </div>
  );
}

export default Profile;