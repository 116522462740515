import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Login.css';
import { handleApiError } from '../utils/errorHandler';
import useAuth from '../hooks/useAuth';

function Login() {
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [userType, setUserType] = useState('apprentice');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });

  const navigate = useNavigate();
  const { login } = useAuth();

  const checkPasswordStrength = (pass) => {
    setPasswordStrength({
      length: pass.length >= 8,
      uppercase: /[A-Z]/.test(pass),
      lowercase: /[a-z]/.test(pass),
      number: /[0-9]/.test(pass),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(pass),
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
  };

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!isValidEmail(email)) {
      toast.error('Please enter a valid email address');
      setIsLoading(false);
      return;
    }

    try {
      if (isLoginMode) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });

        const data = await response.json();

        if (response.ok) {
          toast.success('Login successful');
          login(data.token, data.email, data.userType);
          navigate('/upload-question');
        } else {
          toast.error(data.message || 'Login failed');
        }
      } else {
        if (password !== confirmPassword) {
          toast.error('Passwords do not match');
          setIsLoading(false);
          return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password, userType }),
        });

        const data = await response.json();

        if (response.ok) {
          toast.success('Registration successful. Please check your email for verification.');
          navigate(`/verify-email?email=${encodeURIComponent(email)}`);
        } else {
          toast.error(data.message || 'Registration failed');
        }
      }
    } catch (error) {
      console.error('Unexpected error during authentication:', error);
      toast.error(handleApiError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2>{isLoginMode ? 'Login' : 'Register'}</h2>
      {!isLoginMode && (
        <>
          <p className="instruction">Please select your role:</p>
          <div className="user-type-selection">
            <div className={`slider ${userType}`}></div>
            <button
              type="button"
              className={userType === 'apprentice' ? 'active' : ''}
              onClick={() => setUserType('apprentice')}
            >
              Apprentice
            </button>
            <button
              type="button"
              className={userType === 'sensei' ? 'active' : ''}
              onClick={() => setUserType('sensei')}
            >
              Sensei
            </button>
          </div>
        </>
      )}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
          required
        />
        {!isLoginMode && (
          <>
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <div className="password-strength">
              <p>Password strength:</p>
              <ul>
                <li className={passwordStrength.length ? 'valid' : ''}>At least 8 characters</li>
                <li className={passwordStrength.uppercase ? 'valid' : ''}>Contains uppercase letter</li>
                <li className={passwordStrength.lowercase ? 'valid' : ''}>Contains lowercase letter</li>
                <li className={passwordStrength.number ? 'valid' : ''}>Contains number</li>
                <li className={passwordStrength.special ? 'valid' : ''}>Contains special character</li>
              </ul>
            </div>
          </>
        )}
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Processing...' : (isLoginMode ? 'Login' : 'Register')}
        </button>
      </form>
      <p className="switch-form">
        {isLoginMode ? "Don't have an account? " : "Already have an account? "}
        <button type="button" onClick={() => setIsLoginMode(!isLoginMode)}>
          {isLoginMode ? 'Register' : 'Login'}
        </button>
      </p>
    </div>
  );
}

export default Login;