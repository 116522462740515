// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-verification-container {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: var(--bg-secondary);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .email-verification-container h2 {
    color: var(--accent-primary);
    margin-bottom: 1rem;
  }
  
  .instruction {
    color: var(--text-secondary);
    margin-bottom: 1.5rem;
  }
  
  .email-verification-container input {
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid var(--accent-primary);
    border-radius: 5px;
    background-color: var(--bg-primary);
    color: var(--text-primary);
  }
  
  .email-verification-container button {
    width: 100%;
    padding: 0.8rem;
    background-color: var(--accent-primary);
    color: var(--text-primary);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .email-verification-container button:hover:not(:disabled) {
    background-color: var(--accent-secondary);
  }
  
  .email-verification-container button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .error {
    color: #ff6b6b;
    margin-bottom: 1rem;
  }
  
  .success-message {
    color: #4caf50;
  }`, "",{"version":3,"sources":["webpack://./src/pages/EmailVerification.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,qCAAqC;IACrC,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,4BAA4B;IAC5B,mBAAmB;EACrB;;EAEA;IACE,4BAA4B;IAC5B,qBAAqB;EACvB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,uCAAuC;IACvC,kBAAkB;IAClB,mCAAmC;IACnC,0BAA0B;EAC5B;;EAEA;IACE,WAAW;IACX,eAAe;IACf,uCAAuC;IACvC,0BAA0B;IAC1B,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yCAAyC;EAC3C;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".email-verification-container {\n    max-width: 400px;\n    margin: 2rem auto;\n    padding: 2rem;\n    background-color: var(--bg-secondary);\n    border-radius: 10px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n  \n  .email-verification-container h2 {\n    color: var(--accent-primary);\n    margin-bottom: 1rem;\n  }\n  \n  .instruction {\n    color: var(--text-secondary);\n    margin-bottom: 1.5rem;\n  }\n  \n  .email-verification-container input {\n    width: 100%;\n    padding: 0.8rem;\n    margin-bottom: 1rem;\n    border: 1px solid var(--accent-primary);\n    border-radius: 5px;\n    background-color: var(--bg-primary);\n    color: var(--text-primary);\n  }\n  \n  .email-verification-container button {\n    width: 100%;\n    padding: 0.8rem;\n    background-color: var(--accent-primary);\n    color: var(--text-primary);\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .email-verification-container button:hover:not(:disabled) {\n    background-color: var(--accent-secondary);\n  }\n  \n  .email-verification-container button:disabled {\n    opacity: 0.7;\n    cursor: not-allowed;\n  }\n  \n  .error {\n    color: #ff6b6b;\n    margin-bottom: 1rem;\n  }\n  \n  .success-message {\n    color: #4caf50;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
