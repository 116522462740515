import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
  return (
    <div className="home">
      <section className="hero">
        <h1>Get Expert Answers Through Video Calls</h1>
        <p>Connect with professionals and get your questions answered in real-time</p>
        <Link to="/ask" className="cta-button">Ask a Question</Link>
      </section>
      <section className="features">
        <div className="feature">
          <i className="fas fa-video"></i>
          <h3>Video Calls</h3>
          <p>Face-to-face interactions for better understanding</p>
        </div>
        <div className="feature">
          <i className="fas fa-users"></i>
          <h3>Expert Network</h3>
          <p>Connect with professionals across various fields</p>
        </div>
        <div className="feature">
          <i className="fas fa-file-alt"></i>
          <h3>File Sharing</h3>
          <p>Share documents and screenshots during calls</p>
        </div>
      </section>
      <section className="maintenance-notice">
        <div className="notice-content">
          <h2>Coming Soon!</h2>
          <p>Our product is currently under maintenance. We'll be back shortly with amazing features!</p>
        </div>
      </section>
    </div>
  );
}

export default Home;