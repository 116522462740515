import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import './EmailVerification.css';
import { handleApiError } from '../utils/errorHandler';

function EmailVerification() {
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
    } else {
      navigate('/login');
    }
  }, [location, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/verify-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, code: verificationCode }),
      });

      const data = await response.json();

      if (response.ok) {
        setIsVerified(true);
        toast.success('Email verified successfully');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        toast.error(data.message || 'Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during email verification:', error);
      toast.error(handleApiError(error));
    } finally {
      setIsLoading(false);
    }
  };


  
  return (
    <div className="email-verification-container">
      <h2>Email Verification</h2>
      {isVerified ? (
        <div className="success-message">
          <p>Your email has been successfully verified!</p>
          <p>Redirecting to login page...</p>
        </div>
      ) : (
        <>
          <p className="instruction">
            Please enter the verification code sent to your email: {email}
          </p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Verifying...' : 'Verify Email'}
            </button>
          </form>
        </>
      )}
    </div>
  );
}

export default EmailVerification;